import { useStorage } from '@vueuse/core'
import useErrorMapper from './useErrorMapper'
import type { IField } from '~/types/collection'

export default () => {
  const state = useState<{
    loading: boolean
    item: IField
    form: IField
    errors: Map<string, { message: string }>
  }>('auth', () => ({
    loading: true,
    item: {},
    form: {},
    errors: new Map<string, { message: string }>()
  }))
  const storage = useStorage<{
    id: number | undefined
  }>('auth', {
    id: undefined
  })
  const cookie = useCookie('auth_token')

  const canSome = (permissions: string[]) => {
    if (Array.isArray(state.value.item.user.role.permissions) && Array.isArray(permissions)) {
      return permissions.some(item => state.value.item.user.role.permissions.includes(item))
    }

    return false
  }

  const can = (permission: string) => {
    if (Array.isArray(state.value.item.user.role.permissions) && permission) {
      return state.value.item.user.role.permissions.includes(permission)
    }

    return false
  }

  const actions = {
    async profile () {
      if (cookie && Object.values(state.value.item).length) {
        return true
      }

      mutations.setLoading(true)

      const [error, data] = await useFetchApi({
        path: '/profile'
      })

      mutations.setLoading(false)

      if (data) {
        mutations.setItem(data)

        return true
      }
      if (error) {
        return false
      }
    },

    async login () {
      mutations.setLoading(true)

      const [error, data] = await useFetchApi({
        path: '/auth',
        method: 'POST',
        body: {
          ...state.value.form
        }
      })

      if (error) {
        mutations.setErrors(error.data.data)
      }

      mutations.setLoading(false)

      return [error, data]
    },

    async logout () {
      mutations.setLoading(true)

      const [error, data] = await useFetchApi({
        path: '/auth/logout'
      })

      mutations.setLoading(false)

      return [error, data]
    }
  }

  const mutations = {
    setItem (item: IField) {
      state.value.item = item
    },

    setErrors (errors: string) {
      state.value.errors = useErrorMapper(errors).errors
    },

    setLoading (loading: boolean) {
      state.value.loading = loading
    },

    setValue (field: string, value: any) {
      state.value.form[field] = value

      if (field === 'id') {
        storage.value.id = value
      }
    },

    resetItem () {
      state.value.item = {}
      state.value.form = {}
    },

    resetErrors () {
      state.value.errors = new Map<string, { message: string }>()
    }
  }

  return {
    state,
    storage,
    canSome,
    can,
    actions,
    mutations
  }
}
